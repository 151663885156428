import { ArrowForwardIos as BaseArrowForwardIos } from "@mui/icons-material";
import { Button as BaseButton, Typography } from "@mui/material";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import IndexJsonLd from "../components/IndexjsonLd";
import LoadingCircular from "../components/loadingCircular";
import notfound from "../images/404.svg";
import topBackground from "../images/background.png";
import { default as Seo } from "../layouts/head";
import Layout from "../layouts/pc";
import LayoutSP from "../layouts/sp";
const NotFoundPage = () => {
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 50);
  }, []);
  const title = "ページが見つかりません。";
  return (
    <>
      <Seo title={title} />
      <IndexJsonLd title={title} />
      {loading ? (
        <LoadingCircular />
      ) : breakpoints.sm ? (
        <LayoutSP>
          <MainDiv>
            <ImgSP src={notfound} alt="notfound" />
            <MainTextSP>ご指定のページが見つかりませんでした。</MainTextSP>
            <DescriptionSP>
              アクセスしようとしたページは削除、変更されたか、現在利用できない可能性があります。
              お手数ですが、ロートリホームページのトップまたは下記リンクよりお探しください。
            </DescriptionSP>
            <ButtonDivSP>
              <ButtonSP component={Link} to="/">
                TOPページへ <ArrowForwardIosSP />
              </ButtonSP>
            </ButtonDivSP>
          </MainDiv>
        </LayoutSP>
      ) : (
        <Layout>
          <MainDiv>
            <Img src={notfound} alt="notfound" />
            <MainText>ご指定のページが見つかりませんでした。</MainText>
            <Description>
              アクセスしようとしたページは削除、変更されたか、現在利用できない可能性があります。
              お手数ですが、ロートリホームページのトップまたは下記リンクよりお探しください。
            </Description>
            <ButtonDiv>
              <Button component={Link} to="/">
                TOPページへ <ArrowForwardIos />
              </Button>
            </ButtonDiv>
          </MainDiv>
        </Layout>
      )}
    </>
  );
};

const MainDiv = styled.div`
  text-align: center;
  background-image: url(${topBackground});
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  width: 100vw;
`;

const Img = styled.img`
  padding-top: 100px;
  width: 180px;
`;

const ImgSP = styled.img`
  padding-top: 20px;
  width: 180px;
`;

const MainText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.black.primary};
    font-size: ${theme.fonts.size.xl2};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    margin-top: 50px;
  `}
`;

const MainTextSP = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.black.primary};
    font-size: ${theme.fonts.size.xlsp};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primarySP};
    padding-top: 50px;
    width: 270px;
    margin: 0 auto;
    text-align: left;
  `}
`;

const Description = styled(Typography)`
  ${({ theme }) => css`
    padding-top: 20px;
    color: ${theme.colors.black.primary};
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    width: 727px;
    margin: 0 auto;
    text-align: left;
  `}
`;

const DescriptionSP = styled(Typography)`
  ${({ theme }) => css`
    padding-top: 20px;
    color: ${theme.colors.black.primary};
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primarySP};
    width: 310px;
    margin: 0 auto;
    text-align: left;
  `}
`;

const ButtonDiv = styled.div`
  margin-top: 70px;
  padding-bottom: 190px;
`;

const Button = styled(BaseButton)`
  ${({ theme }) => css`
    color: ${theme.colors.white.primary};
    background-color: ${theme.colors.purple.primary};
    font-size: ${theme.fonts.size.xl2};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    border-radius: 10px;
    padding: 23px 120px;

    &:hover {
      color: ${theme.colors.white.primary};
      background-color: ${theme.colors.purple.primary};
    }
  `}
`;
const ArrowForwardIos = styled(BaseArrowForwardIos)`
  ${({ theme }) => css`
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.xl2};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    line-height: 32px;
  `}
`;

const ButtonDivSP = styled.div`
  margin-top: 20px;
  padding-bottom: 70px;
`;

const ButtonSP = styled(BaseButton)`
  ${({ theme }) => css`
    color: ${theme.colors.white.primary};
    background-color: ${theme.colors.purple.primary};
    font-size: ${theme.fonts.size.sm};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primarySP};
    border-radius: 10px;
    padding: 13px 72px;

    &:hover {
      color: ${theme.colors.white.primary};
      background-color: ${theme.colors.purple.primary};
    }
  `}
`;
const ArrowForwardIosSP = styled(BaseArrowForwardIos)`
  ${({ theme }) => css`
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.sm};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primarySP};
    line-height: 32px;
  `}
`;

export default NotFoundPage;
